import React from 'react'

export default function Chat(props) {
    return (
        <>
            <div className={`chat ${props.isLeft && 'chat-left'}`}>
                <div className="chat-avatar">
                    <a className="avatar avatar-online" data-toggle="tooltip" href="#" data-placement="left" title="" data-original-title="Edward Fletcher">
                        <img src={props.avatar} alt={props.alt} />
                        <i></i>
                    </a>
                </div>
                <div className="chat-body">
                    <div className="chat-content">
                        <p>{props.message}</p>
                        <time className="chat-time" datetime="2015-07-01T11:39">{props.time}</time>
                    </div>
                </div>
            </div>
           
        </>
    )
}
