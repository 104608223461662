import React from 'react'
import Chat from './Chat'

export default function Body(props) {
    const chat = props.chats.map((el,index)=>{
        const isLeft = 'receive'===el.type
        return <Chat alt={el.name} isLeft={isLeft} message={el.message} avatar={isLeft?props.avatar.support:props.avatar.user} time={el.time} key={index}></Chat>
    });

    return (
        
        <div className="panel-body">
            <div className="chats">
            {chat}
            </div>
        </div>
    )
}
