import React from 'react'
import Cleanchat from './Component/Cleanchat'
import './App.css'

export default function App() {
  return (
    <>
    <Cleanchat/>
    </>
  )
}
