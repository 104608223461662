import React, { Component } from 'react'

export default class Footer extends Component {
    constructor(props)
    {
        super(props);
        this.state={input:'',type:'send'}
    }
    handleChange(e){
        this.setState({
            input : e.target.value
        })  
    }
    handleChangeselect(e){
        this.setState({type:e.target.value})
    }

    handleclick(){
        if(this.state.input.length>0)
        {
            this.props.handleSubmit(this.state)
            this.setState({input:''}) 
        }
        else
            alert('Input can,t be empty')
        
    }
    render() {
        return (
            <div className="panel-footer">
                <form>
                    <div className="input-group">
                        <span className="input-group-btn custome_width">
                            <select className="form-control" id="type" onChange={this.handleChangeselect.bind(this)}>
                                <option value="sent">Send</option>
                                <option value="receive">Get</option>
                            </select>
                        </span>
                        <input type="text" className="form-control" value={this.state.input} onChange={this.handleChange.bind(this)} placeholder="Say something" />
                        <span className="input-group-btn">
                            <button className="btn btn-primary" onClick={this.handleclick.bind(this)} type="button">Send</button>
                        </span>
                    </div>
                </form>
            </div>
        )
    }
}
