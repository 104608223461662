import React, { Component } from 'react'
import Body from './Body';
import Footer from './Footer';
import Header from './Header';

export default class Cleanchat extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            title:'chatroom',
            chatList:[
                {
                    name:'Loading',
                    type:'sent',
                    message:'Loading',
                    time:'Loading Time' 
                }                   
            ],
            gravatar:{
                user:'https://bootdey.com/img/Content/avatar/avatar1.png',
                support:'https://bootdey.com/img/Content/avatar/avatar2.png'
            }
        }     
    }
    async getchatdata(){
        const response = await fetch("http://localhost:3001/chats");
        return response.json();
    }

    componentDidMount(){
        this.getchatdata().then((data)=>{
            this.setState({
                chatList:data 
            })
        })
    }
    componentDidUpdate(prevState){
        if(prevState.chatList !== this.state.chatList){
            this.getchatdata().then((data)=>{
                this.setState({
                    chatList:data 
                })
            })  
        }
    }

    handleSubmit(inpdata){
        let idnewchat = this.state.chatList[this.state.chatList.length-1].id + 1

        let headers = {
            "Content-Type": "application/json"
        }
        let newPost = {
            "id": idnewchat,
            "name": "reza",
            "type": inpdata.type,
            "message": inpdata.input,
            "time":new Date().toLocaleTimeString(),
            "image": "3.jpg"
        }        
        fetch("http://localhost:3001/chats?id_ne=1",{
            method:'POST',
            headers:headers,
            body:JSON.stringify(newPost)
        })
            .then(response=>response.json())
            .then(data=>console.log(data))
        /*
        this.setState(()=>{
            return {
                ...this.state,
                chatList:[
                    ...this.state.chatList,
                    {
                        type:'sent',
                        message,
                        time:new Date().toLocaleTimeString()
                    }
                ]
            }
        })
        */
    }
    
    render() {
        return (
            <>
                <div className="container bootstrap snippets">
                    <div className="col-md-10 col-xs-12 col-md-offset-1">
                        <div className="panel" id="chat">
                            <Header title={this.state.title} />
                            <Body chats={this.state.chatList} avatar={this.state.gravatar} />
                            <Footer handleSubmit={this.handleSubmit.bind(this)} />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
